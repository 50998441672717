<template>
  <!-- pc端 -->
  <div class="pc">
    <div
      class="content-mid"
      v-if="guwenInfo"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      "
    >
      <div style="display: flex; align-items: center">
        <div style="font-size: 24px; color: #000">
          Credits that can be withdrawn :
          <span style="color: #044d5a">
            {{ guwenInfo.leftCoinBalance }}
          </span>
          Credits
          <span style="color: red">(${{ guwenInfo.leftCoinBalance / 2 }})</span>
        </div>
      </div>
      <div style="display: flex; align-items: center">
        <div style="width: 310px">
          <el-input
            v-model.number="creditsVal"
            placeholder="Please enter the Credits you want to withdraw"
          />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-icon>
          <Switch />
        </el-icon>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div style="font-size: 24px; font-weight: 700">
          <span style="color: red">${{ changeCoinBalance }}</span>
        </div>
      </div>

      <div class="Instructions">
        <h3>Minimum Withdrawal Amount:</h3>
        <div>
          <span style="font-style: italic;"
            >- Please note that the minimum amount eligible for withdrawal is
            <el-text tag="b">$100</el-text></span
          >
        </div>
        <h3>Withdrawal Fee:</h3>
        <div>
          <span style="font-style: italic;"
            >- For every withdrawal request, there is a cost of
            <el-text tag="b">30%</el-text> that is deducted from the total credits to be
            withdrawn. This fee contributes to the operational and administrative expenses
            of </span
          ><el-text tag="ins">ZestWisdom.</el-text>
        </div>
      </div>

      <el-button type="success" @click="withdrawals">Submit</el-button>
    </div>

    <div class="demo-date-picker pc">
      <div class="block">
        <el-date-picker
          @change="chooseTime"
          v-model="DateTime"
          type="datetimerange"
          :shortcuts="shortcuts"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :default-time="defaultTime2"
        />

        <span class="demonstration"
          >Comparing with {{ dateArr[0] ? dateArr[0] : defaultTimeSta }} -
          {{ dateArr[1] ? dateArr[1] : defaultTimeEnd }}</span
        >
      </div>
    </div>

    <div class="cards pc">
      <el-card class="box-card" v-for="(item, index) in headerList" :key="index">
        <template #header>
          <div class="card-header">
            <div></div>
            <div></div>
          </div>
        </template>
        <div class="price">{{ item.value }}</div>
        <div class="text">{{ item.name }}</div>
      </el-card>
    </div>

    <div id="box">
      <div class="box-header">
        <div>Earnings overview</div>
        <div>
          <!-- <el-button type="primary" plain>Primary</el-button>
                <el-button type="success" plain>Success</el-button> -->
        </div>
      </div>
      <div class="box-date">
        Comparing with {{ dateArr[0] ? dateArr[0] : defaultTimeSta }} -
        {{ dateArr[1] ? dateArr[1] : defaultTimeEnd }}
      </div>
      <div id="main">
        <!-- 图表 -->
      </div>
    </div>

    <div id="box1">
      <div class="box-header">
        <div>Working time</div>
        <div>
          <!-- <el-button type="primary" plain>Primary</el-button>
                <el-button type="success" plain>Success</el-button> -->
        </div>
      </div>
      <div class="box-date">
        Comparing with {{ dateArr[0] ? dateArr[0] : defaultTimeSta }} -
        {{ dateArr[1] ? dateArr[1] : defaultTimeEnd }}
      </div>
      <div id="main2">
        <!-- 图表 -->
      </div>
    </div>

    <!-- -------------------------------------------------------------------   移动    ---------------------------------------------- -->

    <!-- <div class="right-word">
      <h3>{{ $t("setting.liveBroadcastManagement") }}</h3>
      <div class="content-name">
        <p class="content-user">{{ $t("setting.roomIndex") }}</p>
        <p class="code-div" style="padding-left: 28px; margin-top: 22px">
          {{ roomIndex }}
        </p>
        <el-button @click="joinRoom" type="primary">Join Room</el-button>
      </div>
      <div class="content-name">
        <p class="content-user">{{ $t("setting.extractServer") }}</p>
        <p class="code-div active-copy" @click="copyUrl" ref="urlToCopy">
          <el-tooltip class="item" effect="dark" content="Click to copy" placement="top">
            {{ pushUrl }}
          </el-tooltip>
        </p>
      </div>
      <div class="content-name">
        <p class="content-user">{{ $t("setting.streamKey") }}</p>
        <p class="code-div active-copy" @click="copyText" ref="textToCopy">
          <el-tooltip class="item" effect="dark" content="Click to copy" placement="top">
            {{ streamKey }}
          </el-tooltip>
        </p>
      </div>
      <div class="content-buttom">
        <el-button
          type="primary"
          size="small"
          color="#02758a"
          dark="#0f333a"
          @click="openLiveRoom"
        >
          {{ $t("setting.openLiveRoom") }}</el-button
        >

        <el-button type="danger" size="small" @click="closeLiveRoom">
          {{ $t("setting.closeLiveRoom") }}</el-button
        >
      </div>

      <h1>Help</h1>
      <br />
      <el-button @click="openHref" type="warning">Live help</el-button>
    </div> -->
  </div>

  <!-- 移动端 -->
  <div class="mobile main">
    <div v-if="guwenInfo" class="Credits">
      <h4>Credits that can be withdrawn</h4>
      <span style="color: #044d5a">
        {{ guwenInfo.leftCoinBalance }}
      </span>
      Credits
    </div>

    <div class="Credits">
      <div style="width: 310px; margin: auto; margin-top: 10px">
        <el-input
          v-model.number="creditsVal"
          placeholder="Please enter the Credits you want to withdraw"
        />
      </div>

      <div style="margin: 10px">
        <el-icon>
          <Switch />
        </el-icon>
      </div>

      <div>
        <span style="color: red">${{ changeCoinBalance }}</span>
      </div>

      <div style="margin-top: 10px">
        <el-button type="success" @click="withdrawals">submit</el-button>
      </div>
    </div>

    <!-- <div class="footer">
      <div class="title">
        <h3>{{ $t("setting.liveBroadcastManagement") }}</h3>
      </div>

      <div class="footer-main">
        <div>
          <p class="user">{{ $t("setting.roomIndex") }}</p>
          <div class="user-text">
            {{ roomIndex }}
          </div>
        </div>

        <div>
          <p class="user">{{ $t("setting.extractServer") }}</p>
          <div class="user-text">
            {{ pushUrl }}
          </div>
        </div>

        <div>
          <p class="user">{{ $t("setting.streamKey") }}</p>
          <div class="user-text">
            {{ streamKey }}
          </div>
        </div>

        <div class="content-buttom">
          <el-button
            type="primary"
            size="small"
            color="#02758a"
            dark="#0f333a"
            @click="openLiveRoom"
          >
            {{ $t("setting.openLiveRoom") }}</el-button
          >

          <el-button type="danger" size="small" @click="closeLiveRoom">
            {{ $t("setting.closeLiveRoom") }}</el-button
          >
        </div>
        <div class="join">
            <el-button @click="joinRoom" type="primary">Join </el-button>
        </div>

        <div class="help">
            <h1>Help</h1>
      <br />
      <el-button @click="openHref" type="warning">Live help</el-button>
            
        </div>
      </div>
    </div> -->
  </div>
</template>
<script setup>
import * as echarts from "echarts";
import { size } from "lodash";
import { getEchartsData } from "@/api/center.js";
import { id } from "element-plus/es/locale";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import { ref, reactive, onMounted, computed, watch, nextTick } from "vue";
import { openLive, closeLive } from "@/api/profileInfo";
import { Switch } from "@element-plus/icons-vue";
import request from "@/utils/request.js";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { fa } from "element-plus/es/locale";
import { phoneStore } from "../../../store/phone1";
const store1 = phoneStore();
const router = useRouter();
const route = useRoute();
const store = useStore();
const pushUrl = computed(() => {
  return store.state.liveRoom?.pushUrl;
});
const streamKey = computed(() => {
  return store.state.liveRoom?.streamKey;
});
const roomIndex = computed(() => {
  return store.state.liveRoom?.roomIndex;
});
// const pushUrl = ref('')
// const streamKey = ref('')
// const roomIndex = ref('')
const changeCoinBalance = ref(0);
const guwenInfo = ref(null);
const URL = process.env.VUE_APP_BASE_URL;
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const creditsVal = ref("");
let textToCopy = ref();
let urlToCopy = ref();
const headerList = ref([]);
const dataList = ref([]);
const dataList2 = ref([]);
const DateTime = ref("");
const dateArr = ref([]);
const consultantId = ref(null);
const defaultTimeSta = ref(new Date().setMonth(new Date().getMonth() - 1));
const defaultTimeEnd = ref(new Date());

const defaultTime2 = [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)];

const shortcuts = [
  {
    text: "Last week",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      return [start, end];
    },
  },
  {
    text: "Last month",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      return [start, end];
    },
  },
  {
    text: "Last 3 months",
    value: () => {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
  },
];

const copyUrl = () => {
  let textToCopy1 = urlToCopy.value;
  let tempInput = document.createElement("input");
  tempInput.value = textToCopy1.textContent;
  document.body.appendChild(tempInput);

  tempInput.select();
  tempInput.setSelectionRange(0, 99999);

  document.execCommand("copy");
  document.body.removeChild(tempInput);

  ElMessage({ type: "success", message: "Copied to clipboard!" });
};

const copyText = () => {
  let textToCopy1 = textToCopy.value;
  let tempInput = document.createElement("input");
  tempInput.value = textToCopy1.textContent;
  document.body.appendChild(tempInput);

  tempInput.select();
  tempInput.setSelectionRange(0, 99999);

  document.execCommand("copy");
  document.body.removeChild(tempInput);

  ElMessage({ type: "success", message: "Copied to clipboard!" });
};

watch(creditsVal, () => {
  postConverted();
});

onMounted(() => {
  let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
  consultantId.value = userInfo.id;
  //默认传参时间
  let query = {
    startTimeD: ProcessingTime(new Date().setMonth(new Date().getMonth() - 1)),
    endTimeD: ProcessingTime(new Date()),
    consultantId: consultantId.value,
  };
  //调Echarts数据
  getList(query);
  //默认时间
  defaultTimeSta.value = zhuanHua(defaultTimeSta.value);
  defaultTimeEnd.value = zhuanHua(defaultTimeEnd.value);
});
onMounted(() => {
  store1.isConsultingSidebar = false;
  getLoginConsuInfo();
});

//选择时间触发事件
const chooseTime = async (e) => {
  let c = e;
  c = c.map((item) => zhuanHua(item));
  dateArr.value = c;

  e = e.map((item) => ProcessingTime(item));
  //转化时间格式
  const query = {
    startTimeD: e[0],
    endTimeD: e[1],
    consultantId: consultantId.value,
  };
  getList(query);
};

//时间转化
const zhuanHua = (dateString) => {
  // const dateString = "Sat May 06 2023 00:00:00 GMT+0800";
  const date = new Date(dateString);

  const monthIndex = date.getMonth();
  const day = date.getDate();

  // 定义英文月份的数组
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // 获取指定索引处的英文月份
  const month = months[monthIndex];
  return `${month} ${day}`;
};

//时间转换函数
const ProcessingTime = (time) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 月份从0开始，所以需要加1
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const paddedHours = hours < 10 && hours >= 0 ? `0${hours}` : hours;
  const paddedMinutes = minutes < 10 && minutes >= 0 ? `0${minutes}` : minutes;
  const paddedSeconds = seconds < 10 && seconds >= 0 ? `0${seconds}` : seconds;
  return `${year}-${month}-${day} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

//获取数据
const getList = async (params) => {
  let res = await getEchartsData(params);
  if (res.code == "200") {
    let a = res.data;
    let allPrice = a.sumPerMinutes + a.sumChatGift + a.sumLiveGift + a.sumGift;
    dataList.value = [
      {
        value: a.sumPerMinutes,
        name: "Private Session",
        text: ((a.sumPerMinutes / allPrice) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumChatGift,
        name: "Private Session Gift",
        text: ((a.sumChatGift / allPrice) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumLiveGift,
        name: "Live",
        text: ((a.sumLiveGift / allPrice) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumGift,
        name: "Gift",
        text: ((a.sumGift / allPrice) * 100).toFixed(1) + "%",
      },
    ];
    dataList.value.map((item) => {
      if (item.text == "NaN%" || item.text == "Infinity%" || item.text == "-Infinity%") {
        return (item.text = "0%");
      }
      return item;
    });

    let allPrice2 = a.sumTimeLiveSeconds + a.sumTimeChatSeconds;
    dataList2.value = [
      {
        value: a.sumTimeLiveSeconds,
        values: a.sumTimeLive,
        name: "Free Live",
        text: ((a.sumTimeLiveSeconds / allPrice2) * 100).toFixed(1) + "%",
      },
      {
        value: a.sumTimeChatSeconds,
        values: a.sumTimeChat,
        name: "Private Session",
        text: ((a.sumTimeChatSeconds / allPrice2) * 100).toFixed(1) + "%",
      },
    ];
    headerList.value = [
      { name: "Total Earnings", value: "$" + a.TotalRevenue },
      { name: "Avg/Hour", value: "$" + a.svnRevenue },
      { name: "Private Session", value: a.sumTimeChat },
      { name: "Live", value: a.sumTimeLive },
      { name: "New Members", value: a.sumFollower },
    ];

    dataList2.value.map((item) => {
      if (item.text == "NaN%" || item.text == "Infinity%" || item.text == "-Infinity%") {
        return (item.text = "0%");
      }
      return item;
    });

    initEcharts(dataList.value);
    initEcharts2(dataList2.value);
  }
};

//初始化图表
const initEcharts = (data) => {
  console.log("进入图表一", data);
  let chartDom = document.getElementById("main");
  let myChart = echarts.init(chartDom);

  let option;
  option = {
    toolbox: {
      showTitle: false,
    },
    color: ["#9252f3", "#fbcb3e", "#f05486", "red"],
    grid: {
      containLabel: true,
      left: "10%", // 设置图表容器左边距
      top: "10%", // 设置图表容器上边距
      right: "10%", // 设置图表容器右边距
      bottom: "10%", // 设置图表容器下边距
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "17%",
      left: "50%",
      orient: "vertical", // 将图例垂直排列
      itemGap: 50,
      right: "50%",
      formatter: (name) => {
        if (data.length > 0) {
          const item = data.filter((item) => item.name == name)[0];
          return (
            "{title|" +
            item.name +
            "}{value|" +
            "$" +
            item.value +
            "}{text|" +
            item.text +
            "}"
          );
        }
      },
      textStyle: {
        rich: {
          title: {
            color: "#333333",
            fontSize: 20,
            padding: [3, 20, 3, 10],
            width: 100,
            fontSize: 14,
          },
          value: {
            fontSize: 22,
            lineHeight: 20,
            width: 80,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
          text: {
            fontSize: 22,
            lineHeight: 20,
            width: 40,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
        },
      },
    },
    series: [
      {
        name: "Earnings overview",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        center: ["30%", "50%"],
        label: {
          show: false,
          position: "center",
          formatter: function (params) {
            return params.name + ": " + "$" + params.value;
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };

  option && myChart.setOption(option);
  window.addEventListener("resize", function () {
    // 调用图表实例的 resize 方法
    // console.log("111");
    myChart && myChart.resize();
  });
};

//初始化图表
const initEcharts2 = (data) => {
  console.log("进入图表二", data);
  let chartDom = document.getElementById("main2");
  let myChart = echarts.init(chartDom);
  let option;

  option = {
    toolbox: {
      showTitle: false,
    },
    color: ["#f05486", "#9252f3"],
    grid: {
      containLabel: true,
      left: "10%", // 设置图表容器左边距
      top: "10%", // 设置图表容器上边距
      right: "10%", // 设置图表容器右边距
      bottom: "10%", // 设置图表容器下边距
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "36%",
      left: "50%",
      orient: "vertical", // 将图例垂直排列
      itemGap: 80,
      right: "50%",
      formatter: (name) => {
        if (data.length > 0) {
          const item = data.filter((item) => item.name == name)[0];
          return (
            "{title|" + item.name + "}{value|" + item.values + "}{text|" + item.text + "}"
          );
        }
      },
      textStyle: {
        rich: {
          title: {
            color: "#333333",
            fontSize: 20,
            padding: [3, 20, 3, 10],
            width: 100,
            fontSize: 14,
          },
          value: {
            fontSize: 22,
            lineHeight: 20,
            width: 80,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
          text: {
            fontSize: 22,
            lineHeight: 20,
            width: 40,
            aline: "right",
            padding: [3, 50],
            color: "#333333",
            fontSize: 18,
          },
        },
      },
    },
    series: [
      {
        name: "Working time",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        center: ["30%", "50%"],
        label: {
          show: false,
          position: "center",
          formatter: function (params) {
            return params.name + ": " + params.data.values;
          },
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: data,
      },
    ],
  };

  option && myChart.setOption(option);
  // 监听窗口大小变化
  window.addEventListener("resize", function () {
    // 调用图表实例的 resize 方法
    // console.log("222");
    myChart && myChart.resize();
  });
};

// ---------------------------------------------------------------------------
//开启直播
const openLiveRoom = async () => {
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  let data = {
    liveType: 1,
  };
  let res = await openLive(data);
  if (res.code == 200) {
    loading.close();
    store.commit("setLiveRoom", JSON.stringify(res.data));
    ElMessage({ type: "success", message: "Live streaming started" });
  } else {
    loading.close();
    ElMessage({ type: "error", message: "Failed to enable live streaming" });
  }
};

//关闭直播
const closeLiveRoom = async () => {
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  let res = await closeLive();
  if (res.code == 200) {
    loading.close();
    store.commit("setLiveRoom", null);
    ElMessage({ type: "success", message: "Closed live successfully" });
  } else {
    loading.close();
    ElMessage({ type: "error", message: "Failed to close live stream" });
  }
};
const joinRoom = () => {
  console.log(guwenInfo.value.id, "-----guwenInfo-----");
  router.push({ path: `/detailPage/${guwenInfo.value.id}` });
};

// 提现
function withdrawals() {
  console.log(guwenInfo.value, "guwenInfo");
  console.log(creditsVal.value, "creditsVal.value");
  if (changeCoinBalance.value < 100) {
    return ElMessage("The withdrawal amount is less than one hundred dollars");
  }
  //     if (!guwenInfo.value.paypalEmail) {
  //     return ElMessage('You must first link a PayPal account')
  // } else
  if (!creditsVal.value) {
    return ElMessage("Cannot be empty");
  } else if (guwenInfo.value.leftCoinBalance - creditsVal.value <= 0) {
    return ElMessage("You don't have enough Credits");
  }
  if (guwenInfo.value.leftCoinBalance - creditsVal.value > 0) {
    submit();
  }
}

async function postConverted() {
  if (creditsVal.value == "") {
    changeCoinBalance.value = 0;
    return;
  }
  const { code, data } = await request.post("/system/faConsultant/converted", {
    coinBalance: creditsVal.value,
  });
  if (code == 200) {
    changeCoinBalance.value = data;
  }
}
async function postWithdrawApply() {
  const { code, data } = await request.post("/system/FaWithdrawal/withdrawApply", {
    coinBalance: creditsVal.value,
  });
  if (code == 200 && data) {
    getLoginConsuInfo();
    ElMessage({
      type: "success",
      message: "Submit successfully",
    });
    creditsVal.value = "";
    setTimeout(() => {
      router.push("/profileInfo/WithdrawalList");
    }, 500);
  }
}
function submit() {
  ElMessageBox.confirm("Are you sure to submit?", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
  })
    .then(() => {
      postWithdrawApply();
    })
    .catch(() => {});
}
async function getLoginConsuInfo() {
  console.log("请求用户信息");
  const { code, data } = await request.get("/system/faConsultant/getLoginConsuInfo");
  if (code == 200) {
    guwenInfo.value = data;
    // 储存用户信息
    store.commit("setUserInfo", JSON.stringify(data));
  }
}
function openHref() {
  window.open(window.location.origin + "/#/obsHelp");
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  :deep(.el-text) {
    font-size: 16px !important;
  }
  .demo-date-picker {
    display: flex;
    width: 50%;
    padding: 0;
    flex-wrap: wrap;
  }

  .demo-date-picker .block {
    display: flex;
    padding: 30px 0;
    text-align: center;
    border-right: solid 1px var(--el-border-color);
    margin-left: 10px;
    color: #8d76a0;
    // flex: 1;
  }

  .demo-date-picker .block:last-child {
    border-right: none;
  }

  .demo-date-picker .demonstration {
    display: block;
    color: #8d76a0;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .demonstration {
    width: 300px;
    margin-top: 5px;
  }

  .cards {
    width: 100%;
    height: 180px;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-around;

    /*水平滚动条*/

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }

    .box-card {
      width: 180px;
      height: 150px;
      background-color: #f4f1f8;
      margin-bottom: 20px;
    }

    .price {
      color: purple;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  #box1,
  #box {
    width: 100%;
    height: 500px;
    background: #f4f1f8;
    border-radius: 6px;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column; /* 或 row，根据需要选择 */

    .box-date {
      padding: 20px;
      color: #1a8f75;
    }

    .box-header {
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      color: #9b6f80;
      font-size: 25px;
      font-weight: 600;
    }

    #main {
      width: 100%;
      height: 100%;
    }

    #main2 {
      width: 100%;
      height: 100%;
    }
  }

  .mobile {
    display: none !important;
  }

  .right-word {
    padding: 22px;

    h3 {
      line-height: 50px;
    }

    .content-name {
      display: flex;
      height: 50px;
      color: black;
      font-size: 18px;
      width: 100%;
      margin-top: 5px;
      align-items: center;

      .content-user {
        width: 200px;
      }

      .code-div {
        min-height: 50px;
        padding: 5px;
        line-height: 21px;
        border-radius: 5px;
        width: 80%;
        word-wrap: break-word;
        font-size: 16px;
        margin-top: 15px;
      }

      .active-copy {
        cursor: pointer;
      }
    }

    .content-buttom {
      width: 100%;
      text-align: center;
      height: 70px;
      margin-top: 30px;
    }
  }

  .content-head {
    width: 100%;
    background-color: #110101;
    display: flex;
    height: 60px;

    .head-icon {
      width: 70px;
      background-color: #ddda20;
      text-align: center;
      line-height: 60px;
    }

    .head-word {
      color: #ede6f4;
      font-size: 12px;
      line-height: 28px;
      margin-left: 20px;

      span {
        color: #ddda20;
        cursor: pointer;
      }
    }
  }

  .content-mid {
    height: 370px;
    background-color: rgb(245, 241, 248);
    margin: 15px;

    .Instructions {
      margin-top: 20px;
      margin: 0px 12rem;
      padding: 10px;

      background-color: rgb(222, 241, 248);

      h3 {
        line-height: 30px;
        font-style: italic;
      }

      div {
        line-height: 20px;
        font-size: 16px;
      }
    }
  }
}

//移动端
@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }

  .main {
    width: 100%;
    box-sizing: border-box;

    overflow: hidden;

    .Credits {
      line-height: 30px;
      text-align: center;
    }

    .footer {
      padding: 20px 0;
      position: relative;

      .help {
        margin-top: 20px;
      }

      .join {
        position: absolute;
        top: 10px;
        right: 0px;
      }

      .content-buttom {
        margin-top: 20px;
        text-align: center;
      }

      .footer-main {
        .user {
          color: red;
          margin-top: 20px;
        }
        .user-text {
          padding: 10px 10px;
          line-height: 24px;
          background: #eee;
          border-radius: 6px;
          width: 100%; /* 设置容器宽度 */
          overflow-wrap: break-word; /* 允许单词内部断行 */
        }
      }
    }
  }
}
</style>
